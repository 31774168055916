<template>
  <!-- 上传并预览 -->
  <nh-dialog
    v-model:visible="dialogVisible"
    title="导入"
    @close="handleClose"
  >
    <div class="dialog_content">
      <div class="buttons">
        <el-upload
          ref="upload"
          :on-error="uploadError"
          :limit="1"
          :headers="myHeaders"
          :on-success="uploadSuccess"
          :action="uploadURL"
          multiple
        >
          <nh-button type="primary">
            点击上传
          </nh-button>
          <nh-button
            type="primary"
            @click="downTemp"
          >
            模板下载
          </nh-button>
        </el-upload>
      </div>

      <div class="table">
        <el-table
          ref="multipleTable"
          v-loading="loading.getTableData"
          :data="pageTableData"
          border
        >
          <el-table-column
            v-for="item in TABLECOLUMN"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <span v-if="item.filter">{{ filterLabel(scope.row[item.prop], item.arr) }}</span>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
        <nh-pagination
          class="margin-top_normal"
          v-bind="pagination"
          @change="handlePaginationChange"
        />
      </div>
    </div>
    <template #tip>
      <div class="el-upload__tip">
        温馨提示：
        <p>
          1、单次最多只能导入1000条
        </p>
        <div class="clear-btn">
          <nh-button>
            清空数据
          </nh-button>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <nh-button
          type="grey"
          @click="onClose"
        >
          取 消
        </nh-button>
        <nh-button
          type="primary"
          @click="onSubmit"
        >
          确 定
        </nh-button>
      </div>
    </template>
  </nh-dialog>
</template>

<script>
// import { getToken } from '@/utils/auth';
import { TABLECOLUMN } from './fileds';

export default {
  name: 'FluentRackInfoImport',
  emits: ['submitSuccess'],
  data() {
    return {
      dialogVisible: false,
      TABLECOLUMN,
      myHeaders: {
        // token: getToken()
      },
      isUpload: true,
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      tableData: [],
      loading: {
        getTableData: false,
      },
      uploadURL: '/hope-saas-base-web/bmsStation/uploadStation',
      downURL: '/hope-saas-base-web/excel/station_import.xlsx',
      saveLoading: false,
    };
  },
  computed: {
    total() {
      return this.tableData.length;
    },
    pageTableData() {
      const start = this.pagination.size * (this.pagination.page - 1);
      const end = start + this.pagination.size;
      return this.tableData.slice(start, end);
    },
  },
  methods: {
    init() {
      this.dialogVisible = true;
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
    },
    // 上传成功后的回调
    uploadSuccess(response) {
      if (response.code === 0) {
        this.tableData = response.data;
        this.isUpload = response.entitys;
      } else {
        this.$message({
          type: 'error',
          message: response.message,
        });
      }
      if (response.data === null) {
        this.tableData = [];
      }
    },
    // 上传错误
    uploadError() {
      this.$message({
        type: 'error',
        message: '上传失败，请重试！',
      });
    },
    downTemp() {
      window.location.href = this.downURL;
    },
    // 上传
    async onSubmit() {
      //   await saveUploadStation(this.viewData.stationList)
      this.$refs.upload.clearFiles();
      this.$emit('submitSuccess');
      this.onClose();
    },
    onClose() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
<style>
.el-scrollbar__wrap {
  overflow-x: hidden;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.table {
  padding-top: 8px;
}

.clear-btn {
  text-align: right;
}
</style>
