import { FormType } from '@/constant/form';

const DICTIONARIES = {
  ADD: '新建',
  EDIT: '编辑',
  DETAILS: '详情',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  sortingLine: 'SORTING_LINE',
  storeType: 'STORE_TYPE',
  WOEK_LOCATION: 'workLocation',
  enableStatus: 'ENABLE_STATUS',
  usedStatus: 'USED_STATUS',
  sortingUnit: 'SORTING_UNIT',
};
const filterTable = ['sortingLine', 'storeType',
  'enableStatus', 'usedStatus', 'sortingUnit'];

const OPTIONS = {
  SORTING_LINE: [
    {
      label: '1号分拣线',
      value: 'NO1',
    },
  ],
  STORE_TYPE: [
    {
      label: '整托区',
      value: 'PALLET',
    }, {
      label: '整箱区',
      value: 'BOX',
    }, {
      label: '拆零区',
      value: 'SCATTER',
    },
  ],
  ENABLE_STATUS: [
    {
      label: '启用',
      value: DICTIONARIES.ENABLED,
    }, {
      label: '禁用',
      value: DICTIONARIES.DISABLED,
    },
  ],
  USED_STATUS: [
    {
      label: '闲置',
      value: 'IDLE',
    }, {
      label: '占用',
      value: 'USED',
    },
  ],
  SORTING_UNIT: [
    {
      label: '整单位',
      value: 'BIG',
    }, {
      label: '零单位',
      value: 'SMALL',
    },
  ],
};
export {
  DICTIONARIES, filterTable, OPTIONS,
};

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}

export const TABLECOLUMN = [
  {
    type: 'selection',
    width: 55,
  },
  {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '分拣线',
    prop: 'sortingLine',
    minWidth: 80,
    formatter: (row) => filterLabel(row.sortingLine, OPTIONS[DICTIONARIES.sortingLine]),
  }, {
    label: '库位',
    prop: 'locationCode',
    minWidth: 80,
  }, {
    label: '流利架号',
    prop: 'rackNo',
    minWidth: 160,
  }, {
    label: '所属工位',
    prop: 'workLocation',
    minWidth: 80,
  }, {
    label: '区域类型',
    prop: 'storeType',
    minWidth: 80,
    formatter: (row) => filterLabel(row.storeType, OPTIONS[DICTIONARIES.storeType]),
  }, {
    label: '分拣单位',
    prop: 'sortingUnit',
    minWidth: 80,
    formatter: (row) => filterLabel(row.sortingUnit, OPTIONS[DICTIONARIES.sortingUnit]),
  }, {
    label: '启停状态',
    prop: 'enableStatus',
    minWidth: 80,
    formatter: (row) => filterLabel(row.enableStatus, OPTIONS[DICTIONARIES.enableStatus]),
  }, {
    label: '使用状态',
    prop: 'usedStatus',
    minWidth: 80,
    formatter: (row) => filterLabel(row.usedStatus, OPTIONS[DICTIONARIES.usedStatus]),
  }, {
    label: '长度（CM）',
    prop: 'length',
    minWidth: 100,
  }, {
    label: '宽度（CM）',
    prop: 'width',
    minWidth: 100,
  }, {
    label: '高度（CM）',
    prop: 'height',
    minWidth: 100,
  },
  {
    label: '操作',
    prop: 'action',
    fixed: 'right',
    width: 120,
  }];

export function serchFields(WORK_LOCATION_NO_CODE) {
  return [
    {
      label: '分拣线',
      prop: 'sortingLine',
      component: FormType.SELECT,
      options: OPTIONS.SORTING_LINE,
    }, {
      label: '库位',
      prop: 'locationCode',
      component: FormType.INPUT,
    }, {
      label: '流利架号',
      prop: 'rackNo',
      component: FormType.INPUT,
    }, {
      label: '区域类型',
      prop: 'storeType',
      component: FormType.SELECT,
      options: OPTIONS.STORE_TYPE,
    }, {
      label: '所属工位',
      prop: 'workLocation',
      component: FormType.SELECT,
      options: WORK_LOCATION_NO_CODE,
    }, {
      label: '启停状态',
      prop: 'enableStatus',
      component: FormType.SELECT,
      options: OPTIONS.ENABLE_STATUS,
    }, {
      label: '使用状态',
      prop: 'usedStatus',
      component: FormType.SELECT,
      options: OPTIONS.USED_STATUS,
    },
  ];
}
