const DICTIONARIES = {
};
export {
  DICTIONARIES,
};
export const initForm = {
  sortingLine: null,
  locationCode: null,
  rackNo: null,
  storeType: null,
  workLocation: null,
  enableStatus: null,
  sortingUnit: null,
  length: null,
  width: null,
  height: null,
  description: null,
};
export const TABLECOLUMN = [{
  label: '分拣线',
  prop: 'dockCode',
  width: 100,
}, {
  label: '库位',
  prop: 'zoneCode',
  width: 80,
}, {
  label: '流利架号',
  prop: 'status',
  width: 120,
}, {
  label: '区域类型',
  prop: 'usedStatus',
  width: 80,
}, {
  label: '所属工位',
  prop: 'zoneUsed1',
  width: 80,
}, {
  label: '启停状态',
  prop: 'zoneUsed2',
  width: 80,
}, {
  label: '使用状态',
  prop: 'zoneUsed3',
  width: 80,
}, {
  label: '分拣单位',
  prop: 'zoneUsed4',
  width: 80,
}, {
  label: '长度（CM）',
  prop: 'zoneUsed5',
  width: 100,
}, {
  label: '宽度（CM）',
  prop: 'zoneUsed6',
  width: 100,
}, {
  label: '高度（CM）',
  prop: 'zoneUsed7',
  width: 100,
},
];
