<template>
  <pl-block>
    <PlForm
      v-model="searchForm"
      mode="search"
      :fields="serchFields(WORK_LOCATION_NO_CODE)"
      @submit="handleSearch"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @selection-change="handleSelectionChange"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/流利架管理/[新建]'
          }"
          @click="handleAdd"
        >
          新增
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/流利架管理/[启用]'
          }"
          @click="handleStatus(DICTIONARIES.ENABLED)"
        >
          启用
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/流利架管理/[停用]'
          }"
          @click="handleStatus(DICTIONARIES.DISABLED)"
        >
          停用
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/流利架管理/[导入]'
          }"
          @click="handleInfoImport"
        >
          导入
        </nh-button>
      </template>
      <template #workLocation="{row}">
        <span>{{ filterLabel(row.workLocation, WORK_LOCATION_NO_CODE) }}</span>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleEdit(row)">
          编辑
        </nh-button>
        <nh-button type="text" @click="handleDetails(row)">
          详情
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <edit-or-detail ref="edit" @submitSuccess="getTableData" />
  <info-import ref="info" />
</template>
<script>
import loadingMixin from '@thales/loading';
import EditOrDetail from './components/editOrDetail.vue';
import InfoImport from './components/infoImport.vue';
import {
  TABLECOLUMN, DICTIONARIES, filterTable, serchFields, OPTIONS,
} from './fileds';
import mixin from './mixin';
import { fluentRackPage, fluentRackEnable } from './api';

export default {
  name: 'FluentRack',
  components: {
    EditOrDetail,
    InfoImport,
  },
  mixins: [loadingMixin, mixin],
  data() {
    return {
      TABLECOLUMN,
      serchFields,
      DICTIONARIES,
      filterTable,
      OPTIONS,
      tableData: [],
      searchForm: {},
      selectIds: [],
      loading: {
        getTableData: false,
      },
      checkedColumns: [],
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleSearch() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      this.selectIds = [];
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await fluentRackPage(this.searchForm, pagination);
      this.pagination.total = result.total;
      this.tableData = result.records;
    },
    handleAdd() {
      const info = {
        title: '新建',
      };
      this.$refs.edit.init(info);
    },
    handleEdit(rows) {
      const info = {
        title: '编辑',
        id: rows.id,
      };
      this.$refs.edit.init(info);
    },
    handleDetails(rows) {
      const info = {
        title: '详情',
        id: rows.id,
      };
      this.$refs.edit.init(info);
    },
    handleInfoImport() {
      this.$refs.info.init();
    },
    handleSelectionChange(selection) {
      this.selectIds = selection.map((item) => item.id);
    },
    async handleStatus(status) {
      const findStatus = OPTIONS.ENABLE_STATUS.find((item) => item.value === status);
      if (this.selectIds.length === 0) {
        return;
      }
      try {
        const { label } = findStatus;
        await this.$confirm(`是否确认 ${label}?`, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
        await fluentRackEnable({
          statusEnum: status,
          ids: [...this.selectIds],
        });
        this.$message({
          type: 'success',
          message: `${label}成功!`,
        });
        this.getTableData();
      } catch {
        this.$message({
          type: 'success',
          message: '你已经取消!',
        });
      }
    },
    filterLabel(val, arr) {
      const findResult = arr && arr.find((item) => item.value === val);
      return findResult && findResult.label;
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
