import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:流利架新增
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/11972
 */
export function fluentRackInsert(data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_rack/insert', data);
}
/**
 * @description:流利架修改
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/11974
 */
export function fluentRackUpdate(data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_rack/update', data);
}
/**
 * @description:流利架 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/11976
 */
export function fluentRackGet(params) {
  return wmsPlusHttp.get('/warehouse_automation/fluent_rack/get', {}, {
    params,
  });
}
/**
 * @description:流利架 根据ID删除
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/11978
 */
export function fluentRackDelete(params) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_rack/delete', {}, {
    params,
  });
}
/**
 * @description:流利架 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/11980
 */
export function fluentRackPage(data, params) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_rack/page', data, {
    params,
  });
}
/**
 * @description:启用禁用
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/11982
 */
export function fluentRackEnable(data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_rack/enable', data);
}
