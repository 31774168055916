<template>
  <nh-dialog
    v-model="dialogVisible"
    :title="title"
    :track="{
      name_zh: `基础数据/仓库相关/流利架管理/弹窗-${title}`,
      trackName: $route.path
    }"
    @close="handleClose"
  >
    <nh-dialog-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :hide-required-asterisk="isDetail"
    >
      <nh-dialog-form-item label="分拣线：" prop="sortingLine">
        <span v-if="isDetail">{{ filterLabel(form.sortingLine, OPTIONS.SORTING_LINE) }}</span>
        <el-select
          v-else
          v-model="form.sortingLine"
          placeholder="请选择"
        >
          <el-option
            v-for="item in OPTIONS.SORTING_LINE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="库位：" prop="locationCode">
        <span v-if="isDetail">{{ form.locationCode }}</span>
        <el-input v-else v-model="form.locationCode" />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="流利架号：" prop="rackNo">
        <span v-if="isDetail">{{ form.rackNo }}</span>
        <el-input v-else v-model="form.rackNo" />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="储存类型：" prop="storeType">
        <span v-if="isDetail">{{ filterLabel(form.storeType, OPTIONS.STORE_TYPE) }}</span>
        <el-select
          v-else
          v-model="form.storeType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in OPTIONS.STORE_TYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item
        label="所属工位："
        prop="workLocation"
      >
        <span v-if="isDetail">{{ filterLabel(form.workLocation, WORK_LOCATION_NO_CODE) }}</span>
        <el-select
          v-else
          v-model="form.workLocation"
          placeholder="请选择"
        >
          <el-option
            v-for="item in WORK_LOCATION_NO_CODE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="状态：" prop="enableStatus">
        <span v-if="isDetail">{{ filterLabel(form.enableStatus, OPTIONS.ENABLE_STATUS) }}</span>
        <el-select
          v-else
          v-model="form.enableStatus"
          placeholder="请选择"
        >
          <el-option
            v-for="item in OPTIONS.ENABLE_STATUS"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="分拣单位：" prop="sortingUnit">
        <span v-if="isDetail">{{ filterLabel(form.sortingUnit, OPTIONS.SORTING_UNIT) }}</span>
        <el-select
          v-else
          v-model="form.sortingUnit"
          placeholder="请选择"
        >
          <el-option
            v-for="item in OPTIONS.SORTING_UNIT"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="长度：" prop="length">
        <span v-if="isDetail">{{ form.length }}</span>
        <el-input v-else v-model.number="form.length" />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="宽度：" prop="width">
        <span v-if="isDetail">{{ form.width }}</span>
        <el-input v-else v-model.number="form.width" />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="高度：" prop="height">
        <span v-if="isDetail">{{ form.height }}</span>
        <el-input v-else v-model.number="form.height" />
      </nh-dialog-form-item>
      <nh-dialog-form-item
        label="描述："
        prop="description"
        :span="24"
      >
        <span v-if="isDetail">{{ form.description }}</span>
        <el-input
          v-else
          v-model="form.description"
          type="textarea"
          :rows="2"
          placeholder=""
        />
      </nh-dialog-form-item>
    </nh-dialog-form>
    <template #footer>
      <div>
        <nh-button
          v-if="isDetail"
          :loading="loading.onSubmitForm"
          @click="onClose"
        >
          关 闭
        </nh-button>
        <template v-else>
          <nh-button
            type="grey"
            @click="onClose"
          >
            取 消
          </nh-button>
          <nh-button
            type="primary"
            :loading="loading.onSubmitForm"
            @click="onSubmitForm"
          >
            确 定
          </nh-button>
        </template>
      </div>
    </template>
  </nh-dialog>
</template>
<script>
import loadingMixin from '@thales/loading';
import { DICTIONARIES, OPTIONS } from '../fileds';
import { initForm } from './fileds';
import mixin from '../mixin';
import { fluentRackGet, fluentRackInsert, fluentRackUpdate } from '../api';

export default {
  name: 'FluentRackEditOrDetail',
  mixins: [loadingMixin, mixin],
  emits: ['submitSuccess'],
  data() {
    const checkNum = (rule, value, callback) => {
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'));
      } else if (value <= 0) {
        callback(new Error('不能小于0'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      DICTIONARIES,
      OPTIONS,
      initForm,
      title: '',
      isDetail: false,
      form: initForm,
      loading: {
        onSubmitForm: false,
      },
      rules: {
        sortingLine: [
          { required: true, message: '请选择分拣线', trigger: 'change' },
        ],
        locationCode: [
          { required: true, message: '请输入库位', trigger: 'blur' },
        ],
        rackNo: [
          { required: true, message: '请输入流利架号', trigger: 'blur' },
        ],
        storeType: [
          { required: true, message: '请选择储存类型', trigger: 'change' },
        ],
        workLocation: [
          { required: true, message: '请选择所属工位', trigger: 'change' },
        ],
        enableStatus: [
          { required: true, message: '请选择状态', trigger: 'change' },
        ],
        sortingUnit: [
          { required: true, message: '请选择分拣单位', trigger: 'change' },
        ],
        length: [
          { required: true, message: '请输入长度', trigger: 'blur' },
          { validator: checkNum, trigger: 'blur' },
        ],
        width: [
          { required: true, message: '请输入宽度：', trigger: 'blur' },
          { validator: checkNum, trigger: 'blur' },
        ],
        height: [
          { required: true, message: '请输入高度', trigger: 'blur' },
          { validator: checkNum, trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    init(data) {
      this.dialogVisible = true;
      const { title = '', id = '' } = data;
      this.title = title;
      this.id = id;
      this.isDetail = title === DICTIONARIES.DETAILS;
      this.$nextTick(() => {
        if (title !== DICTIONARIES.ADD) {
          this.getDetail();
        }
      });
    },
    async getDetail() {
      const resp = await fluentRackGet({ id: this.id });
      this.form = { ...this.form, ...resp };
    },
    async onSubmitForm() {
      await this.$refs.ruleForm.validate();
      if (this.id) {
        await fluentRackUpdate({ id: this.id, ...this.form });
      } else {
        await fluentRackInsert(this.form);
      }
      this.$message({
        type: 'success',
        message: `${this.id ? '编辑' : '新增'}成功!`,
      });
      this.$emit('submitSuccess');
      this.onClose();
    },
    onClose() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
    },
    filterLabel(val, arr) {
      const findResult = arr && arr.find((item) => item.value === val);
      return findResult && findResult.label;
    },
  },
};
</script>
